// extracted by mini-css-extract-plugin
export const sectionSpacing = "blog-post-module--section-spacing--31X03";
export const rowSpacing = "blog-post-module--row-spacing--1xeNn";
export const rowSpacingSml = "blog-post-module--row-spacing-sml--uB51Q";
export const intro = "blog-post-module--intro--2mx7z";
export const postTopBar = "blog-post-module--postTopBar--k2-fX";
export const separator = "blog-post-module--separator--vroOJ";
export const imageWrapper = "blog-post-module--imageWrapper--1iPTt";
export const postHeader = "blog-post-module--postHeader--3u0et";
export const metaData = "blog-post-module--metaData--RLwCu";
export const postBox = "blog-post-module--postBox--lTJFc";