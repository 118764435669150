// import dependencies
import React from "react";
import {navigate} from "gatsby";
import {getGatsbyImageData} from "gatsby-source-sanity";
import {GatsbyImage} from "gatsby-plugin-image";
import PortableText from "@sanity/block-content-to-react";
import Serializers from "../utils/serializers";

// import components
import Layout from "../components/layout";
import Section from "../components/section";
import SeparatorTopGrey from "../components/separators/separator-top-grey";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import * as styles from "./styles/blog-post.module.scss";

const sanityConfig = {
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET
}

export default function BlogPost({pageContext}) {
    const imageData = getGatsbyImageData(pageContext.mainImage.asset.id, {maxWidth: 750, maxHeight: 400}, sanityConfig);

    return (
<Layout>
    <Section color={"white"}>
        <div className={`container ${styles.intro}`}>
            <div className={`row ${styles.postTopBar}`}>
                <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                    <button aria-label="Go back"  onClick={() => { navigate(-1) }}><FontAwesomeIcon icon={["fas", "arrow-left"]} /></button>
                    <div className={styles.separator}></div>
                </div>
            </div>
            <div className={`row ${styles.postHeader}`}>
                <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                    <div className={`${styles.imageWrapper}`}>
                        <GatsbyImage image={imageData} alt={`foto ${pageContext.title}`}/>    
                    </div>
                    <h1>{pageContext.title}</h1>
                    <div className={styles.metaData}>
                        <h4>Por: {pageContext.author}</h4>
                        <h5>Publicado en: {pageContext.date}</h5>
                    </div>
                    <div className={styles.separator}></div>
                </div>
            </div>
            <div className={`row ${styles.postBox}`}>
                <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                    <PortableText blocks={pageContext.body} serializers={Serializers} />
                </div>
            </div>
        </div>
        <SeparatorTopGrey></SeparatorTopGrey>
    </Section>
</Layout>
    );
}